import React, { useState, useRef, useContext } from "react";
import { Mutation } from "@apollo/client/react/components";
import styled from "styled-components";
import nanoid from "nanoid";
import { Formik } from "formik";
import * as Yup from "yup";
import Context from "@common/website/components/context/shared_utils";

import { CREATE_CONTACT_FORM_MUTATION } from "@common/website/gql/mutations/lead.gql";

import headerBg from "@common/website/assets/images/bg.png";
import kjopLogo from "@common/website/assets/images/kjop_logo.png";

import SectionItem from "./section_item";
import Status from "@common/website/components/routes/properties/shared/register/status";
import FormField from "@common/website/components/shared/form/lib/form_field";
import SubmitButton from "@common/website/components/shared/form/lib/submit_button";

const PRODUCTION = process.env.NODE_ENV === "production";

const basis = [
	{
		id: nanoid(),
		text: "Vi inngår en oppdragsavtale",
		hotspot: "Oppdragsavtalen kan sendes rett til din mailboks, og du signerer enkelt og raskt med din BankID."
	},
	{
		id: nanoid(),
		text: "Finansiering avklares",
		hotspot: "Vi hjelper deg med å avklare finansiering."
	},
	{
		id: nanoid(),
		text: "Du finner selv aktuelle boliger og sender de til oss",
		hotspot:
			"Aktuelle objekter kan være markedsført gjennom hvilken som helst megler i Norge. Bruk gjerne vår app, «Mitt Boligkjøp», til å søke etter bolig. Denne laster du enkelt ned via App Store eller Google Play."
	},
	{
		id: nanoid(),
		text: "Vi gjennomgår salgsoppgaver før visning",
		hotspot:
			"Vi bistår i vurderingen av aktuelle boliger og ser blant annet på prisantydning opp mot område, kvaliteter ved boligen og eventuelle svakheter. Mot et tillegg blir vi gjerne med deg på visning."
	},
	{
		id: nanoid(),
		text: "Vi gir deg råd i budprosessen",
		hotspot:
			"Vi leverer konkrete og taktiske råd underveis, så du kan ta bedre avgjørelser. Vår oppgave er å jobbe for at du skal få kjøpt til en best mulig pris."
	},
	{
		id: nanoid(),
		text: "Vi gir deg råd før kontraktsmøtet",
		hotspot:
			"Vi går igjennom kjøpskontrakten og vedlegg sammen, og passer på at kontrakten er fullstendig og rettferdig. Det er viktig at begge parter er innforstått med hva kontrakten sier."
	},
	{
		id: nanoid(),
		text: "Vi gir deg råd i forbindelse med overtakelsen",
		hotspot:
			"Vi forbereder deg på hvilke ting du må være klar over ved overtakelse. Ønsker du at vi deltar, kan vi gjøre det mot et tillegg. Der sørger vi for at dine rettigheter blir ivaretatt, riktig avlesning av strøm og vannforbruk."
	},
	{
		id: nanoid(),
		text: "Vi kan hjelpe begge parter med oppgjøret",
		hotspot:
			"Normalt vil selgers megler stå for oppgjøret. Skulle derimot begge parter ønske det, kan vår oppgjørsavdeling håndtere transaksjonen mellom partene."
	}
];

const premium = [
	{
		id: nanoid(),
		text: "Vi inngår en oppdragsavtale",
		hotspot: "Oppdragsavtalen kan sendes rett til din mailboks og du signerer enkelt og raskt med din BankID."
	},
	{
		id: nanoid(),
		text: "Vi kartlegger dine behov og ønsker",
		hotspot:
			"Vi gjør oss kjent med dine preferanser som beliggenhet, pris og andre krav du stiller til en aktuell bolig."
	},
	{
		id: nanoid(),
		text: "Finansiering avklares",
		hotspot: "Vi hjelper deg med å avklare finansiering."
	},
	{
		id: nanoid(),
		text: "Vi søker etter boliger for deg",
		hotspot:
			"Vi bruker vår kunnskap og kontaktnett for å identifisere boliger som tilfredsstiller dine preferanser. Det inkluderer også boliger som ikke er lagt ut for salg."
	},
	{
		id: nanoid(),
		text: "Vi markedsfører kjøpsinteresse mot boligeiere",
		hotspot:
			"Vi kontakter eiere av boliger som er aktuelle, men ikke lagt ut for salg. Når vi gjør det på vegne av en konkret, potensiell kjøper, er det lettere for selger å starte en prosess."
	},
	{
		id: nanoid(),
		text: "Vi gjennomfører befaring for deg om ønskelig",
		hotspot:
			"Dersom eier av boligen er interessert i å selge, besøker vi boligen for å forsikre oss om at den matcher dine preferanser. Du vil først få en tilbakemelding om vårt inntrykk av boligen og selgers vurderinger med tanke på et eventuelt salg."
	},
	{
		id: nanoid(),
		text: "Vi vurderer bolig, salgsoppgave og takst",
		hotspot:
			"Vi bistår i vurderingen av aktuelle boliger, og ser blant annet på prisantydning opp mot område, kvaliteter ved boligen og eventuelle svakheter. Dersom selger ikke har megler, lager vi en lovpålagt salgsoppgave."
	},
	{
		id: nanoid(),
		text: "Vi deltar på visning",
		hotspot:
			"Basert på den informasjonen vi har, legger vi en plan for hva vi skal se etter og undersøke nærmere på visning. Dersom det ikke foreligger en tilstandsrapport, kan vi ordne det."
	},
	{
		id: nanoid(),
		text: "Vi deltar i forhandlinger og budprosess",
		hotspot:
			"Vi leverer konkrete og taktiske råd slik at du kan ta bedre avgjørelser. Vi deltar i forhandlinger direkte med selger eller selgers megler."
	},
	{
		id: nanoid(),
		text: "Vi gir råd om og utformer kjøpskontrakt",
		hotspot:
			"Dersom selger ikke har megler, utformer vi kjøpskontrakten mellom partene. Vi passer uansett på at kontrakten er fullstendig og rettferdig. Det er viktig at begge parter er innforstått med hva kontrakten sier."
	},
	{
		id: nanoid(),
		text: "Vi deltar i kontraktsmøtet",
		hotspot:
			"Vi er tilstede og bistår deg for å sikre en trygg og ryddig prosess i kontraktsmøtet. Dersom selger har egen megler, vil vi tilby å delta eller gi deg råd i forkant av kontraktsmøtet."
	},
	{
		id: nanoid(),
		text: "Vi deltar på overtakelsen",
		hotspot:
			"Vi deltar på overtakelsen og sørger for at dine rettigheter blir ivaretatt, riktig avlesning av strøm og vannforbruk. Dette gir deg som kjøper en trygghet i den siste fasen av boligkjøpet."
	},
	{
		id: nanoid(),
		text: "Vi kan hjelpe begge parter med oppgjøret",
		hotspot:
			"Dersom selger har egen megler, vil selgers megler normalt håndtere oppgjøret mellom dere. Dersom selger ikke har egen megler, vil vår oppgjørsavdeling kunne håndtere transaksjonen."
	}
];

const initialFormState = {
	values: {
		name: {
			placeholder: "Navn *",
			width: "100%",
			value: PRODUCTION ? "" : "Test",
			validation: Yup.string()
				.min(2, "Fornavnet må være minst to bokstaver")
				.required("Påkrevd")
		},
		zip: {
			placeholder: "Postnr *",
			width: "30%",
			value: PRODUCTION ? "" : "1234",
			validation: Yup.string()
				.min(4, "Ugylding postnummer")
				.max(4, "Ugylding postnummer")
				.matches(/^[0-9]*$/, "Ugyldig postnummer")
				.required("Påkrevd")
		},
		phone: {
			placeholder: "Telefon *",
			width: "70%",
			value: PRODUCTION ? "" : "12345678",
			validation: Yup.string()
				.matches(/^\s*(\d\s*){8}$/, { excludeEmptyString: true, message: "Ugyldig telefonnummer" })
				.required("Påkrevd")
		},
		email: {
			placeholder: "Epost *",
			width: "100%",
			value: PRODUCTION ? "" : "martin@reeltime.no",
			validation: Yup.string()
				.email("Ugyldig epost")
				.required("Påkrevd")
		}
	}
};

const Kjop = () => {
	const sharedUtils = useContext(Context);
	const formRef = useRef(null);
	const [activeHotspot, setActiveHotspot] = useState(null);
	const [showVideo, setShowVideo] = useState(false);
	return (
		<>
			<Header>
				<KjopeLogo />
			</Header>
			<Container>
				<FullSection>
					<SubTitle>KJØP er en tjeneste for deg som skal kjøpe bolig</SubTitle>
					<Ingress>
						La en av Norges beste eiendomsmeglere bistå deg ved kjøp av bolig. Du har to nivåer å velge mellom, og
						bestemmer selv hvor mye vi skal gjøre for deg. Neste trekk er ditt.
					</Ingress>
					<VideoImage>
						<PlayButton onClick={() => setShowVideo(true)} />
					</VideoImage>
				</FullSection>
				<Sections>
					<Section>
						<SectionTitle>Basis</SectionTitle>
						<SectionDescription>
							La en av Norges beste eiendomsmeglere bistå deg med strategi og taktikk når du skal kjøpe bolig.
						</SectionDescription>
						<SectionItems>
							{basis.map(e => (
								<SectionItem
									key={e.id}
									activeHotspot={activeHotspot}
									setActiveHotspot={setActiveHotspot}
									hotspotId={e.id}
									hotspot={e.hotspot}
								>
									{e.text}
								</SectionItem>
							))}
						</SectionItems>
					</Section>
					<Section>
						<SectionTitle>Premium</SectionTitle>
						<SectionDescription>
							Fortell oss hva du ser etter i din neste bolig, og la en av Norges beste eiendomsmeglere gjøre resten.
						</SectionDescription>
						<SectionItems>
							{premium.map(e => (
								<SectionItem
									key={e.id}
									activeHotspot={activeHotspot}
									setActiveHotspot={setActiveHotspot}
									hotspotId={e.id}
									hotspot={e.hotspot}
								>
									{e.text}
								</SectionItem>
							))}
						</SectionItems>
					</Section>
				</Sections>
				<FullSection>
					<Title>Stå sterkere</Title>
					<FormDescription>
						Ønsker du å stå sterkere i ditt boligkjøp, send oss skjemaet under. En av våre eiendomsmeglere vil da ta
						kontakt med deg for en prat om dine ønsker og behov.
					</FormDescription>
					<Mutation mutation={CREATE_CONTACT_FORM_MUTATION}>
						{createContactForm => (
							<Formik
								isInitialValid={!PRODUCTION}
								initialValues={{
									values: Object.entries(initialFormState.values).reduce(
										(acc, [k, v]) => ({
											...acc,
											[k]: v.value
										}),
										{}
									)
								}}
								validationSchema={Yup.object().shape({
									values: Yup.object().shape(
										Object.entries(initialFormState.values).reduce(
											(acc, [k, v]) => ({
												...acc,
												...(v.validation && {
													[k]: v.validation
												})
											}),
											{}
										)
									)
								})}
								onSubmit={(values, actions) => {
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: "smooth"
									});

									const submit = diggerId => {
										createContactForm({
											variables: {
												input: {
													values: {
														...values.values,
														comment: "Ønsker tilbud på kjøpsmegling (PrivatMegleren KJØP)."
													},
													src: "Kjøp",
													referrer: "https://privatmegleren.no/kjop",
													digger_id: diggerId ? diggerId : null
												}
											}
										}).catch(e => console.error(e));
									};

									sharedUtils.digger
										.formSubmit(
											values.values.zip
												? {
														zip: values.values.zip,
														type: "Kjøp"
												  }
												: {}
										)
										.then(async res => {
											let id = res.result && res.result.id ? res.result.id : null;
											submit(id);
										})
										.catch(() => submit());

									console.log(values);
									setTimeout(() => {
										actions.setSubmitting(false);
										actions.setStatus("success");
									}, 2000);

									setTimeout(() => {
										actions.setStatus(null);
										actions.resetForm();
									}, 4000);
								}}
								render={({
									handleSubmit,
									handleChange,
									handleBlur,
									setFieldValue,
									values,
									touched,
									errors,
									isValid,
									isSubmitting,
									status
								}) => (
									<Form onSubmit={handleSubmit} ref={formRef}>
										{isSubmitting || status === "success" ? (
											<Status isSubmitting={isSubmitting} status={status} />
										) : null}
										{Object.entries(values.values)
											.filter(([k]) => k !== "comment")
											.map(([k, v]) => (
												<FormField
													key={k}
													fieldName={k}
													type="text"
													name={`values.${k}`}
													width={
														initialFormState.values[k] && initialFormState.values[k].width
															? initialFormState.values[k].width
															: null
													}
													placeholder={initialFormState.values[k]?.placeholder}
													touched={touched && touched.values && touched.values[k] ? true : false}
													error={errors && errors.values && errors.values[k]}
													value={values.values[k]}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											))}
										<SubmitButton primary text="Send inn skjema" isValid={isValid ? true : false} />
										<PrivacyLink href="/personvern">Personvernpolicy</PrivacyLink>
									</Form>
								)}
							/>
						)}
					</Mutation>
				</FullSection>
			</Container>
			{showVideo ? (
				<VideoModal
					onClick={e => {
						setShowVideo(false);
					}}
				>
					<div className="video-container">
						<div className="video-container-inner">
							<iframe
								title="Kjøp Video"
								src="https://player.vimeo.com/video/396420457?autoplay=1"
								width="100%"
								height="100%"
								frameBorder="0"
							></iframe>
						</div>
					</div>
				</VideoModal>
			) : null}
		</>
	);
};

const PlayButton = ({ onClick }) => {
	return (
		<StyledPlayButton
			onClick={e => {
				e.preventDefault();
				onClick();
			}}
		>
			<img
				alt=""
				src="https://cdn.reeltime.no/pm_assets/klikk_assets/img/d54b97f0ac44c51ebff510e990c09103.png"
				width="80px"
			/>
			<span>Se video</span>
		</StyledPlayButton>
	);
};

const VideoModal = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.8);
	z-index: 9999;
	cursor: pointer;

	.video-container {
		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		width: 75%;
		height: 100vh;
	}

	.video-container-inner {
		display: block;
		position: absolute;
		width: 100%;

		&::before {
			content: "";
			float: left;
			padding-bottom: 56.25%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			border: 0 !important;
		}
	}
`;

const StyledPlayButton = styled.a`
	padding: 0;
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	span {
		margin-top: 20px;
		color: white;
	}
`;

const PrivacyLink = styled.a`
	width: 100%;
	margin-top: 40px;
	font-size: 1.1rem;
	color: white;
	text-align: center;
	text-decoration: none;
`;

const Container = styled.div`
	padding-top: calc(72px + 48px);
	max-width: 1040px;
	margin: 0 auto;
	padding: 18px;
	align-self: center;
`;

const Header = styled.header`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50vh;
	margin-top: 72px;
	background: url(${headerBg});
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	${({ theme }) => theme.mediaQueries.tablet`
		background-size: contain;
		height: 75vh;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		background-size: contain;
		height: 75vh;
	`};
`;

const KjopeLogo = styled.div`
	display: flex;
	width: 80%;
	height: 80%;
	max-width: 400px;
	max-height: 400px;
	margin-top: 72px;
	background: url(${kjopLogo});
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;

	${({ theme }) => theme.mediaQueries.tablet`
		width: 50%;
		height: 50%;
		max-height: 50%;
		max-width: 50%;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		width: 50%;
		height: 50%;
		max-height: 50%;
		max-width: 50%;
	`};
`;

const FullSection = styled.section`
	display: flex;
	flex-flow: column;
	text-align: center;
	max-width: 720px;
	margin: 0 auto;
`;

const Title = styled.h1`
	margin: 96px 0 24px 0;
	color: ${({ theme }) => theme.colors.gold.primary};
	font-size: 36px;
	font-weight: 400;
`;

const SubTitle = styled.h3`
	margin: 0 0 24px 0;
	color: ${({ theme }) => theme.colors.gold.primary};
	font-family: "Proxima Nova", sans-serif;
	font-weight: 600;
`;

const Ingress = styled.p`
	display: block;
	margin: 0 0 96px 0;
`;

const Sections = styled.div`
	display: block;
`;

const Section = styled.section`
	display: block;
	padding: 36px 18px;
	vertical-align: top;

	${({ theme }) => theme.mediaQueries.tablet`
		display: inline-block;
		width: 50%;
		padding: 0 48px;

		&:last-of-type {
			border-left: ${({ theme }) => `1px solid ${theme.colors.gold.primary}`};
		}
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		display: inline-block;
		width: 50%;
		padding: 0 48px;

		&:last-of-type {
			border-left: ${({ theme }) => `1px solid ${theme.colors.gold.primary}`};
		}
	`};
`;

const SectionItems = styled.ul`
	display: block;
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
`;

const SectionTitle = styled.h2`
	color: ${({ theme }) => theme.colors.gold.primary};
	text-align: center;
`;

const SectionDescription = styled.p`
	margin-bottom: 36px;
	text-align: center;
`;

const Form = styled.form`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-bottom: 96px;

	button {
		width: 100%;
		font-size: 24px;
	}
`;

const FormDescription = styled.p`
	display: block;
	margin: 0 0 48px 0;
`;

const VideoImage = styled.div`
	position: relative;
	background-image: url(https://cdn.reeltime.no/pm_assets/img/vidbgkjop.png);
	height: 52vw;
	max-height: 406px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 4em 0;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.6);
		top: 0;
		z-index: 0;
	}
`;

export default Kjop;
