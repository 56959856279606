import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import qMark from "@common/website/assets/images/qm-03.png";

const SectionItem = ({ children, hotspotId, hotspot, setActiveHotspot, activeHotspot }) => {
	const ref = useRef(null);
	const [show, setShow] = useState(false);
	const [height, setHeight] = useState(null);

	useEffect(
		() => {
			if (ref.current) {
				const { height } = ref.current.getBoundingClientRect();
				setHeight(height);
			}
		},
		[show]
	);

	useEffect(
		() => {
			if (height) {
				if (show) {
					setActiveHotspot(hotspotId);
				}
			}
		},
		[show]
	);

	useEffect(
		() => {
			if (activeHotspot !== hotspotId) {
				if (show) {
					setShow(false);
				}
			}
		},
		[activeHotspot]
	);

	return (
		<Item>
			<Hotspot ref={ref} height={height} show={show && activeHotspot === hotspotId}>
				{hotspot}
			</Hotspot>
			{children}
			{hotspot && hotspot.length ? (
				<QMark
					onClick={() => {
						setShow(!show);
					}}
				/>
			) : null}
		</Item>
	);
};

const Item = styled.li`
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-bottom: 12px;

	${({ theme }) => theme.mediaQueries.tablet`
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
	`};
`;

const QMark = styled.div`
	display: inline-flex;
	width: 16px;
	height: 16px;
	margin-left: 6px;
	background: url(${qMark});
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
`;

const Hotspot = styled.div`
	position: absolute;
	top: ${({ height }) => `calc(-${height ? height + 9 : 0}px)`};
	left: 0;
	min-width: 100%;
	padding: 12px;
	border-radius: 4px;
	background: rgba(255, 255, 255, 0.9);
	color: #000;
	opacity: ${({ show }) => (show ? 1 : 0)};
	pointer-events: ${({ show }) => (show ? "all" : "none")};
	transition: ease-in-out 250ms opacity;
`;

SectionItem.propTypes = {
	children: PropTypes.any,
	hotspotId: PropTypes.string,
	hotspot: PropTypes.string,
	setActiveHotspot: PropTypes.func,
	activeHotspot: PropTypes.string
};

export default SectionItem;
